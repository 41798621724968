import { Button, Input, Modal, Space, Table, TableColumnsType, Typography } from 'antd'
import dayjs from 'dayjs'
import { collection, doc, getFirestore, onSnapshot, query, runTransaction } from 'firebase/firestore'
import { useEffect, useMemo, useState } from 'react'
import DefaultLayout from '../../components/DefaultLayout'

function Checker ({ record }: any) {
  return (
    <Space>
      {record.isChecked
        ? <div>Sudah</div>
        : <div>Belum</div>
      }
    </Space>
  )
}

const columns: TableColumnsType<any> = [
  {
    title: 'NO',
    dataIndex: 'no',
    key: 'no'
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: 'Batch',
    dataIndex: 'batch',
    key: 'batch'
  },
  {
    title: 'QTY',
    dataIndex: 'qty',
    key: 'qty'
  },
  {
    title: 'Status Audit',
    dataIndex: 'checked',
    render: (_, record) => <Checker record={record} />
  }
]

export default function Other () {
  const collectionId = `shiplist-${dayjs().format('YYYYMMDD')}`
  const [shiplist, setShiplist] = useState<any[]>([])
  const [search, setSearch] = useState('')
  const filteredShiplist = useMemo(() => {
    if (search) {
      return shiplist.filter(ship => (ship.no || '').toLowerCase().includes(search.toLowerCase()) || (ship.name || '').toLowerCase().includes(search.toLowerCase()))
    } else {
      return [...shiplist]
    }
  }, [shiplist, search])

  const auditState = useMemo(() => {
    return {
      total: shiplist.length,
      hasChecked: shiplist.filter(ship => ship.isChecked).length
    }
  }, [shiplist])

  const getd = async () => {
    const firestore = getFirestore()
    const q = query(collection(firestore, collectionId))
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const shiplist: any = []
      querySnapshot.forEach((doc) => {
        shiplist.push({
          documentId: doc.id,
          collectionId,
          ...doc.data()
        })
      })
      setShiplist(shiplist)
    })

    return () => unsubscribe()
  }

  useEffect(() => {
    getd()
  }, [])

  const handleDeleteAll = () => {
    Modal.confirm({
      title: 'Konfirmasi',
      content: 'DATA YANG DIHAPUS TIDAK DAPAT DIKEMBALIKAN. YAKIN?',
      onOk: async () => {
        const db = getFirestore()
        await runTransaction(db, async (transaction) => {
          for (const row of shiplist) {
            const newItemRef = doc(db, row.collectionId, row.documentId)
            transaction.delete(newItemRef)
          }
        })
      }
    })
  }

  return (
    <DefaultLayout activeMenu='/other'>
      <Space direction='vertical' style={{ width: '100%' }}>
        <Typography.Text>Total data keseluruhan: {auditState.total}</Typography.Text>
        <Typography.Text>Total data sudah diaudit: {auditState.hasChecked}</Typography.Text>
        <Input.Search placeholder='Cari data...' onSearch={q => setSearch(q)} allowClear/>
        <Table dataSource={filteredShiplist} columns={columns}></Table>
        {!!shiplist.length && (<Button danger onClick={handleDeleteAll}>HAPUS SEMUA DATA</Button>)}
      </Space>
    </DefaultLayout>
  )
}
