// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyAUgsp_z_lNToseccHssnw3_DBgdeDZqKM',
  authDomain: 'shiplist-checker.firebaseapp.com',
  projectId: 'shiplist-checker',
  storageBucket: 'shiplist-checker.appspot.com',
  messagingSenderId: '195934037356',
  appId: '1:195934037356:web:c5d8d2ec75d95d71fcbf49',
  measurementId: 'G-6LWGE1BLLH'
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
export default app
