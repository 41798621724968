import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import Audit from './pages/Audit'
import Home from './pages/Home'
import Importer from './pages/Importer'
import NotFound from './pages/NotFound'
import Other from './pages/Other'

const router = createBrowserRouter([
  {
    id: '/',
    path: '/',
    element: <Home />
  },
  {
    id: '/importer',
    path: '/importer',
    element: <Importer />
  },
  {
    id: '/audit',
    path: '/audit',
    element: <Audit />
  },
  {
    id: '/other',
    path: '/other',
    element: <Other />
  },
  {
    id: '*',
    path: '*',
    element: <NotFound />
  }
])

function App () {
  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  )
}

export default App
