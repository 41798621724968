import { Button, message, Modal, Space, Table } from 'antd'
import dayjs from 'dayjs'
import { collection, doc, getCountFromServer, getFirestore, query, runTransaction } from 'firebase/firestore'
import { InputHTMLAttributes, useState } from 'react'
import * as xlsx from 'xlsx'
import DefaultLayout from '../../components/DefaultLayout'

const columns = [
  {
    title: 'NO',
    dataIndex: 'no',
    key: 'no'
  },
  {
    title: 'Name',
    dataIndex: 'name'
  },
  {
    title: 'Batch',
    dataIndex: 'batch'
  },
  {
    title: 'QTY',
    dataIndex: 'qty'
  },
  {
    title: 'Checked',
    dataIndex: 'checked'
  }
]

export default function Importer () {
  const [file, setFile] = useState<File | null>(null)
  const [rows, setRows] = useState<any[]>([])

  const handleFileSelected: InputHTMLAttributes<HTMLInputElement>['onChange'] = async (evt) => {
    const file = evt?.currentTarget?.files?.[0]
    if (!file) return
    setFile(file)
    evt.target.value = ''
  }

  const handleReselectFile = () => {
    setFile(null)
    setRows([])
  }

  const handleLoadData = async () => {
    try {
      if (!file) return
      const data = await file.arrayBuffer()
      const workbook = xlsx.read(data)
      const worksheet = workbook.Sheets.Shiplist
      if (!worksheet) throw new Error('Worksheet Shiplist tidak ditemukan dalam file')
      const json = xlsx.utils.sheet_to_json(worksheet)
      const rows = json.map((row:any) => ({
        no: row?.no || '',
        name: row?.name || '',
        batch: row?.batch || '',
        qty: row?.qty || ''
      }))
      setRows(rows)
    } catch (err: any) {
      Modal.error({ title: 'Kesalahan', content: err?.message || 'Terjadi kesalahan yang tidak diketahui' })
    }
  }

  const handleImport = async () => {
    try {
      const dateId = dayjs().format('YYYYMMDD')
      const db = getFirestore()

      const valid = rows.every(row => row.no && row.name && row.qty)
      if (!valid) throw new Error('Ada data yang tidak valid untuk diproses')
      const countSnap = await getCountFromServer(query(collection(db, `shiplist-${dateId}`)))
      const count = countSnap.data().count
      if (count > 0) throw new Error(`Data pada tanggal yang dipilih sudah pernah diimport (${count} data)`)
      await runTransaction(db, async (transaction) => {
        for (const row of rows) {
          const newItemRef = doc(collection(db, `shiplist-${dateId}`))
          transaction.set(newItemRef, {
            no: row.no,
            name: row.name,
            batch: row.batch,
            qty: row.qty,
            createdAt: dayjs().toDate(),
            isChecked: false
          })
        }
      })
      message.success('Data selesai diimport')
    } catch (err: any) {
      Modal.error({ title: 'Kesalahan', content: err?.message || 'Terjadi kesalahan yang tidak diketahui' })
    }
  }
  return (
    <DefaultLayout activeMenu='/importer'>
      <Space direction='vertical' style={{ width: '100%' }}>
        <div>Pilih file XLSX yang akan diimport datanya.</div>
        {file
          ? (
            <div>
              <div>{file?.name}</div>
              <Space>
                <Button onClick={handleReselectFile}>Ganti File</Button>
                <Button type='primary' onClick={handleLoadData}>Cek Data</Button>
              </Space>

            </div>
            )
          : (
            <div>
              <input type='file' onChange={handleFileSelected} />
            </div>
            )
        }
      </Space>
      {!!rows.length && (
        <Space direction='vertical' style={{ width: '100%' }}>
          <Table dataSource={rows} columns={columns} pagination={false} />
          <Button type='primary' onClick={handleImport}>Import Data Ke Server</Button>
        </Space>
      )}
    </DefaultLayout>
  )
}
