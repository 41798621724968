import { Layout, Menu, MenuProps } from 'antd'
import { ReactNode } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

const items: MenuProps['items'] = [
  {
    label: 'Home',
    key: '/'
  },
  {
    label: 'Import',
    key: '/importer'
  },
  {
    label: 'Audit',
    key: '/audit'
  },
  {
    label: 'Lainnya',
    key: '/other'
  }
]

type DefaultLayoutProps = {
  children: ReactNode
  activeMenu?: string
}
export default function DefaultLayout ({ children, activeMenu = '/' }: DefaultLayoutProps) {
  const navigate = useNavigate()
  const location = useLocation()
  const handleMenuClick: MenuProps['onClick'] = (info) => {
    if (location.pathname !== info.key) navigate(info.key)
  }
  return (
    <Layout>
      <div style={{ minHeight: '100vh' }}>
        <Layout.Header style={{ padding: 0 }}>
          <Menu onClick={handleMenuClick} selectedKeys={[activeMenu]} mode="horizontal" items={items} />
        </Layout.Header>
        <Layout.Content style={{ padding: 16 }}>{children}</Layout.Content>
      </div>

    </Layout>
  )
}
